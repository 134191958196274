.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.startPage {
  width: 100vw;
  height: 100%;
  display: flex;
  background-color: #121212;
  justify-content: center;
  align-items: center;
}

.buttonFilter {
  background: rgb(243, 22, 180);
  background: linear-gradient(54deg, #f316b4 0%, #ff0013 100%);
  color: #fff;
  padding: 20px;
  font-size: 24px;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 20px;
}

.blurredImage {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  opacity: 0.2;
  object-fit: cover;
  z-index: 1;
}

.container {
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  max-height: 100px;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.logo {
  max-width: 150px;
}

.reloadContainer {
  position: absolute;
  top: 20px;
  left: 20px;
  max-height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.reloadButtonContainer {
  margin-right: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.filterSectionHeader {
  position: absolute;
  top: 20px;
  right: 20px;
  max-height: 100px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.filteredIconCheck {
  position: absolute;
  width: 30px;
  height: 30px;
  top: -10px;
  right: -10px;
}

.filterButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reloadButtonContainer p,
.filterButtonContainer p {
  color: #fff;
  margin: 5px 0;
  max-width: 120px;
}

.reloadButton {
  height: 50px;
  width: auto;
  cursor: pointer;
}

.content {
  flex: 1;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: auto;
}

.content .startTitle {
  color: #fff;
  text-align: center;
  width: 100%;
}

.filterActions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.watchTypeSelectorContainer {
  display: flex;
  width: 90vw;
  max-width: 500px;
  flex-direction: row;
  border: 6px solid #fff;
  border-radius: 20px;
  overflow: hidden;
  margin: auto;
}

.watchTypeSelector {
  flex: 1;
  text-align: center;
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  padding: 20px;
  cursor: pointer;
}

.filterSection {
  margin-top: 20px;
  margin-bottom: 40px;
  width: 100%;
}

.genreListContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.genreItem {
  margin: 5px 10px;
  width: auto;
  padding: 10px;
  border: 3px solid #fff;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
}

.providerItem {
  margin: 5px 10px;
  width: auto;
  border-radius: 10px;
  width: 48px;
  height: 48px;
  display: inline-block;
  border: 3px solid transparent;
  cursor: pointer;
  position: relative;
}

.providerItem img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.startActions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.startButtons {
  display: flex;
  flex-direction: row;
}

.thumbYear:focus {
  outline: none;
}

.chooseContainer {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  font-size: 32px;
}

.chooseIcon {
  max-width: 250px;
  cursor: pointer;
}

.resultContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  flex: 1;
}

.posterColumn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.detailColumn {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.poster {
  max-width: 80%;
  max-height: 70vh;
}

.genreList {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.genreMovie,
.genreTvShow {
  border: 2px solid #fe16b4;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
  color: #fe16b4;
  list-style-type: none;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 2vh;
}

.genreTvShow {
  border-color: #ff0013;
  color: #ff0013;
}

.titleContainer,
.directorContainer,
.actorsContainer,
.dataContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  flex-direction: row;
  width: 100%;
}

.actorsContainer {
  align-items: flex-start;
}

.directorContainer,
.actorsContainer {
  margin-top: 15px;
}

.dataContainer {
  margin-top: 20px;
}

.titleContainer h2 {
  flex: 1;
  color: #fff;
  font-size: 3.2vh;
  margin: 0;
  padding-right: 15px;
}

.iconYouTube {
  color: #fff;
  width: 30px;
  font-size: 3.2vh;
  cursor: pointer;
}

.iconDirector {
  color: #ccc;
  width: 22px;
  font-size: 2vh;
  cursor: pointer;
}

.directorContainer p,
.actorsContainer p {
  flex: 1;
  color: #ccc;
  font-size: 2vh;
  margin: 0;
  padding-left: 10px;
}

.overview {
  font-size: 2.5vh;
  color: #ccc;
  max-height: 20vh;
  overflow-y: scroll;
}

.calendarContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  flex-direction: row;
}

.calendarContainer p {
  color: #ccc;
  padding-left: 10px;
  font-size: 1.8vh;
}

.iconCalendar {
  color: #ccc;
  width: 18px;
  font-size: 1.8vh;
  cursor: pointer;
}

.watchContainer h3 {
  font-size: 2.4vh;
  color: #fff;
}

.logoProvider {
  border-radius: 2.3vh;
  margin-right: 10px;
  width: 8vh;
  height: 8vh;
  border: 3px solid transparent;
}

.filterRecap {
  width: calc(100vw - 40px);
  margin: 5px;
  padding: 20px 0 20px 10px;
  border: 2px solid #fe16b4;
  border-radius: 5px;
}

.filterRecap h2 {
  color: #fff;
  margin-top: 0;
  font-size: 3.5vh;
  font-weight: 400;
}

.filterRecapSection h3 {
  color: #fff;
  font-size: 2.9vh;
  font-weight: 400;
  margin-bottom: 5px;
}

.filterRecapSection p {
  color: #fff;
  font-size: 2.7vh;
  font-weight: bold;
  margin-top: 0;
}

.filterRecapList {
  display: flex;
  overflow: scroll;
}

@media screen and (max-width: 992px) {
  .container,
  .startPage {
    height: inherit;
  }
  .resultContainer {
    flex-direction: column;
  }
  .poster {
    margin: 20px 0;
  }
  .resultContainer {
    padding: 20px;
    width: calc(100% - 40px);
  }
  .chooseContainer {
    max-width: 40vw;
  }
  .chooseIcon {
    max-width: 130px;
  }
  .header {
    max-height: 100px;
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
  }
  .logo {
    max-width: 120px;
    padding-left: 20px;
  }
  .reloadContainer {
    position: unset;
    max-height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .reloadButtonContainer {
    margin-right: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .reloadButtonContainer p {
    color: #fff;
    margin: 5px 0;
  }
  .reloadButton {
    height: 40px;
    width: auto;
    cursor: pointer;
  }
  .buttonFooter {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  .buttonSearchAgainMobile {
    color: #fff;
    border-radius: 10px;
    width: calc(100vw - 30px);
    margin: 10px;
    text-align: center;
    font-size: 2.6vh;
    padding: 15px 0;
  }
  .buttonSearchAgainMobile.movie {
    background-color: #fe16b4;
  }
  .buttonSearchAgainMobile.tvShow {
    background-color: #ff0013;
    color: #000;
  }
  .filterButtonContainer {
    display: none;
  }
  .genreMovie,
  .genreTvShow {
    font-size: 2.5vh;
  }
  .titleContainer h2 {
    font-size: 4.2vh;
  }
  .directorContainer p,
  .actorsContainer p {
    font-size: 2.5vh;
  }
  .calendarContainer p {
    font-size: 2.2vh;
  }
  .overview {
    font-size: 3vh;
  }
  .watchContainer h3 {
    font-size: 2.8vh;
  }
  .logoProvider {
    width: 9vh;
    height: 9vh;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
